import type { AntonioError } from '@ackee/antonio-core';
import { isAntonioError } from '@ackee/antonio-core';

import type { ApiError, SaasLimits } from 'types';

export enum ErrorCode {
    IMPORT_VALIDATION_INVALID_FILE = 'e1002',
    IMPORT_VALIDATION_INVALID_SECRETS = 'e1003',
    MISSING_CLIENT_SECRET = 'e1007',
    FIRST_LOGIN_BY_ADMIN = 'e1010',
    ACCOUNT_NOT_IN_WORKSPACE = 'e1017',
    CONFIGURED_SUPER_ADMIN_IS_NOT_AN_ADMIN = 'e1023',
    CONFIGURED_SUPER_ADMIN_USER_DOESNT_EXIST_IN_THE_WORKSPACE = 'e1024',
    SAAS_LIMIT = 'e2000',
}

export type ApiAntonioError<T> = AntonioError<ApiError<T, `${ErrorCode}`>>;

export function isApiAntonioError<T>(e: any): e is ApiAntonioError<T> {
    if (isAntonioError(e)) {
        const error = e as AntonioError<any>;
        return error.data?.errorCode;
    }
    return false;
}

export const isSaasLimitReachedError = (e: any): e is ApiAntonioError<SaasLimits> =>
    isApiAntonioError(e) && e.data.errorCode === ErrorCode.SAAS_LIMIT;

export const isConfiguredInvalidAdminError = (e: any): e is ApiAntonioError<unknown> =>
    isApiAntonioError(e) &&
    [
        ErrorCode.CONFIGURED_SUPER_ADMIN_IS_NOT_AN_ADMIN,
        ErrorCode.CONFIGURED_SUPER_ADMIN_USER_DOESNT_EXIST_IN_THE_WORKSPACE,
    ].includes(e.data.errorCode as ErrorCode);
